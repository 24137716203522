import React, {useState, useEffect} from 'react'
import {useApolloClient, useMutation, useQuery, gql } from '@apollo/client'
import { easings } from 'react-animation'
import InputMask from 'react-input-mask'

import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as CloseSvg} from '../lib/svg/close.svg'

const CHECK_URL  = process.env.REACT_APP_CHECK_URL

function PhoneInput(props) {
  return (
    <InputMask 
      mask='+9(999)999-99-99'
      type={props.type} 
      name={props.name} 
      value={props.value} 
      onBlur={props.onBlur}
      onChange={props.onChange} required />
  )
}

function User({user, actionOrder, actionFull}){
  const apollo = useApolloClient(),
        [isEdit, setEdit] = useState(false),
        [id, setId] = useState(""),
        [fio, setFio] = useState(""),
        [client, setClient] = useState(0),
        [mobile, setMobile] = useState(""),
        [confirm, setConfirm] = useState(false),
        [password, setPassword] = useState(""),
        [wtsapp, setWtsapp] = useState(""),
        [email, setEmail] = useState(""),
        [inn, setInn] = useState(""),
        [resident, setResident] = useState(0),
        [group, setGroup] = useState(0),
        [isError, setError] = useState(false),
        [clientError, setClientError] = useState(""),
        [fioError, setFioError] = useState(""),
        [passwordError, setPasswordError] = useState(""),
        [wtsappError, setWtsappError] = useState(""),
        [mobileError, setMobileError] = useState(""),
        [emailError, setEmailError] = useState(""),
        [innError, setInnError] = useState("")


  function capitalizeWords(string) {
      return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase() })
  }      
  const onChange = event => {
    if(event.target.name == 'fio'){
      let rex = /[A-Za-z0-9]*$/
      let fio = event.target.value.replace(rex, '')
      let words = fio.split(" ")
      fio = capitalizeWords(fio.toLowerCase())
      if(words.length < 2 || words[0] == '' || words[1] == ''){
            setFioError('от 2 до 3 слов, рус.буквы')
            setFio(fio)
      }
      else if(words.length > 3){
            setFioError('')
      }
      else{
            setFioError('')
            setFio(fio)
      }
    }
    else if(event.target.name == 'mobile'){
      let mobile = event.target.value
      if(mobile.includes("_")){
        setMobileError("укажите номер полностью")
        setMobile(mobile)
      }
      else{
        setMobileError('')
        setMobile(mobile)
      }  
    }
    else if(event.target.name == 'password'){
      let password = event.target.value
      if(password.length < 8){
        setPasswordError('от 8 до 12 символов')
      }
      else{
        setPasswordError('')
      }
      if(password.length < 13) setPassword(event.target.value)
    }
    else if(event.target.name == 'wtsapp'){
      let wtsapp = event.target.value
      if(wtsapp.includes("_")){
        setWtsappError("укажите номер полностью")
        setWtsapp(wtsapp)
      }
      else{
        setWtsappError(false)
        setWtsapp(wtsapp)
      }  
    }
    else if(event.target.name == 'email'){
      const value = event.target.value
      if(!value.includes('@') || !value.includes('.')) setEmailError('Укажите верный e-mail')
      else setEmailError('')
      setEmail(event.target.value)
    }
    else if(event.target.name == 'inn'){
      let inn = event.target.value
      if(inn.length < 13){
        setInn(inn)
        setInnError('поле из 12 символов')
      }
      else{
        setInnError('')
      }
    }
    else if(event.target.name == 'client'){
      setClientError('')
      setClient(event.target.value)
    }
    else if(event.target.name == 'resident')setResident(event.target.value)
    else if(event.target.name == 'group')   setGroup(event.target.value)

  //  if(fioError || passwordError || wtsappError || mobileError || innError || clientError) setError(true)
  //  else setError(false)  
  }

  async function checkParam(event){
    return
    let name  = event.target.name,
        value = event.target.value,
        result, data
    if(value.length < 1) return
    result = await fetch(`${CHECK_URL}/${name}/${value}`)
    data   = await result.json()
    if(data.check == 1 && data.id != id){
      setError(true)
      if(data.param == 'client') setClientError('номер уже зарегистрирован') 
      if(data.param == 'wtsapp') setWtsappError('номер уже зарегистрирован') 
      if(data.param == 'mobile') setMobileError('номер уже зарегистрирован') 
      if(data.param == 'email')  setEmailError('e-mail уже зарегистрирован')     
      if(data.param == 'inn')    setInnError('ИНН уже зарегистрирован')     
    }
    else setError(false)
  }   



  const GET_USER = gql`
  query getUser($id: ID, $client: String) {
    user(id: $id, client: $client){
      id
      fio
      inn
      client
      group
      resident
      mobile
      confirm
      email
      wtsapp
      address
      created
      lastdate
      orders{
        id
        title
        track
        status
        created
      }
      fulls{
        id
        title
        track
        status
        created
      }
    }
  }`

  const param = user.length < 8 ? {client: user} : {id: user}
  const {loading, data} = useQuery(GET_USER, { variables: param})

  const EDIT_USER = gql`
  mutation editUser($id: ID, $client: Int, $fio: String, $wtsapp: String, $mobile: String, $email: String, $group: Int, $resident: Int, $inn: String, $password: String) {
    editUser(id: $id, client: $client, fio: $fio, wtsapp: $wtsapp, mobile: $mobile, email: $email, group: $group, resident: $resident, inn: $inn, password: $password)}` 
    
  const letEdit = (e) => {
      e.preventDefault()
      editUser({variables: {id, client: Number(client), fio, wtsapp, mobile, email, group: Number(group), resident: Number(resident), inn, password}})
  } 

  const [editUser, { error }] = useMutation(EDIT_USER, {
      onCompleted: data => {
        apollo.refetchQueries({include: [GET_USER]})
        setEdit(true)
      }
  }) 

  useEffect(() => {
    if(data?.user){
      setClient(data.user.client)
      setId(data.user.id)
      setFio(data.user.fio)
      setPassword(data.user.password)
      setMobile(data.user.mobile)
      setConfirm(data.user.confirm)
      setWtsapp(data.user.wtsapp)
      setInn(data.user.inn)
      setEmail(data.user.email)
      setResident(data.user.resident)
      setGroup(data.user.group)
      setClient(data.user.client)
    }
  }, [data])

  if(loading) return "Загрузка.."
  if(error) return <p className="request-result">{error}</p>
  if(data){
    return (
    <div id="form-user" style={{ animation: `slide-in ${easings.easeInOutQuart} 300ms forwards`}}>
        <div className="head">
            <h3>Пользователь <span>#{client}</span> <b>{fio}</b></h3>
        </div>
        <div className="body">
          <form method="post" onSubmit={letEdit}>
              {/*<fieldset className="field-wrap">
                <input type="number" id="client" name="client" value={client} disabled />
                <label htmlFor="inn">Клиент</label>
                <aside className={clientError != "" ? "error-field error-active" : "error-field"}>{clientError}</aside>
              </fieldset>*/}
              <fieldset className={fioError == "" && fio != "" ? "field-wrap ready" : "field-wrap"}>
                <input type="text" id="fio" name="fio" value={fio} onChange={onChange} />
                <label htmlFor="fio">ФИО <CheckSvg /></label>
                <aside className={fioError != "" ? "error-field error-active" : "error-field"}>{fioError}</aside>
              </fieldset>
              <fieldset className={mobileError == "" && mobile != "" ? "field-wrap ready" : "field-wrap"}>
                <PhoneInput type="tel" id="mobile" name="mobile" value={mobile} onChange={onChange} onBlur={checkParam}  />
                <label htmlFor="mobile">Тел. Мобильный {confirm ? <CheckSvg /> : <><span className="red">неподтвержден</span> <CloseSvg /></>}</label>
                <aside className={mobileError != "" ? "error-field error-active" : "error-field"}>{mobileError}</aside>
              </fieldset>
              <fieldset className={wtsappError == "" && wtsapp != "" ? "field-wrap ready" : "field-wrap"}>
                <PhoneInput type="tel" id="wtsapp" name="wtsapp" value={wtsapp} onChange={onChange} onBlur={checkParam} />
                <label htmlFor="whtsapp">Тел. Ватсап <CheckSvg /></label>
                <aside className={wtsappError != "" ? "error-field error-active" : "error-field"}>{wtsappError}</aside>
              </fieldset>
              <fieldset className={emailError == "" && email != "" ? "field-wrap ready" : "field-wrap"}>
                <input type="text" id="email" name="email" value={email} onChange={onChange} onBlur={checkParam} required />
                <label htmlFor="email">E-mail <CheckSvg /></label>
                <aside className={emailError != "" ? "error-field error-active" : "error-field"}>{emailError}</aside>
              </fieldset>
              <fieldset className={innError == "" && inn != "" ? "field-wrap ready" : "field-wrap"}>
                <input type="text" id="inn" name="inn" value={inn} onChange={onChange} onBlur={checkParam} autoComplete='off' />
                <label htmlFor="inn">ИНН <CheckSvg /></label>
                <aside className={innError != "" ? "error-field error-active" : "error-field"}>{innError}</aside>
              </fieldset>
              <fieldset className={passwordError == "" && password ? "field-wrap ready" : "field-wrap"}>
                <input type="text" id="password" name="password" value={password} onChange={onChange} autoComplete='off' />
                <label htmlFor="password">Новый пароль <CheckSvg /></label>
                <aside className={passwordError != "" ? "error-field error-active" : "error-field"}>{passwordError}</aside>
              </fieldset>
              <fieldset className="field-wrap">
                <select id="resident" name="resident" value={resident} onChange={onChange}>
                  <option value="1">Россия</option>
                  <option value="2">Казахстан</option>
                </select>
                <label htmlFor="inn">Резидент <CheckSvg /></label>
              </fieldset>
              <fieldset className="field-wrap">
                <select id="group" name="group" value={group} onChange={onChange}>
                  <option value="1">Склад Гуанчжоу</option>
                  <option value="2">Склад Алматы</option>
                  <option value="3">Склад Москва</option>
                  <option value="4">Администратор</option>
                  <option value="5">Клиент</option>
                </select>
                <label htmlFor="inn">Группа <CheckSvg /></label>
              </fieldset>
              
              <button className="grn right" type="submit" disabled={isError}>{isEdit ? <>пользователь обновлен</> : <>сохранить <CheckSvg /></>}</button>
              {/*<p className="info">Будьте внимательны при смене номера клиента. В случае если у клиента есть грузы, смена его номера может привести к сбою приложения. Необходимо сначала удалить грузы, затем менять номер.</p>*/}
          </form>
          {data.user.orders.length > 0 && 
          <ul>
            <h3>Грузы пользователя</h3>
            {data.user.orders.map(order => (
              <li key={order.id} onClick={() => actionOrder(order.track)}><span><date>{(new Date(order.created)).toLocaleString()}</date> №{order.track}: {order.title}</span><i></i>
                {order.status == 0 && <b className="red">Не прибыл на склад</b>}
                {order.status == 1 && <b>Прибыл на склад</b>}
                {order.status == 2 && <b>Отправлен по назначению</b>}
                {order.status == 3 && <b>Отправлен в Алматы</b>}
                {order.status == 4 && <b>Отправлен в Москву</b>}
                {order.status == 5 && <b className="grn">Выдан клиенту</b>}
              </li>
            ))}
          </ul>}
          {data.user.fulls.length > 0 && 
          <ul>
          <h3>Фулфилмент пользователя</h3>
            {data.user.fulls.map(order => (
              <li key={order.id} onClick={() => actionFull(order.track)}><span><date>{(new Date(order.created)).toLocaleString()}</date> №{order.track}: {order.title}</span><i></i>
                {order.status == 0 && <b className="red">Новая заявка</b>}
                {order.status == 1 && <b>Связались с клиентом</b>}
                {order.status == 2 && <b>Оплачено</b>}
                {order.status == 3 && <b>Идет сборка</b>}
                {order.status == 4 && <b>Готово к отправке</b>}
                {order.status == 5 && <b>Отправлено</b>}
                {order.status == 6 && <b>Принято на склад</b>}
                {order.status == 7 && <b className="grn">Завершено</b>}
              </li>
            ))}
          </ul>}
      
        </div>
    </div>
    )
  }
}
export default User